import {
	handleQuerySubDomainInfo
} from '@index/api/login';

export default {
	name: 'Login',
	components: {

	},
	data() {
		return {
			domain: {
				schemeName: ''
			},
		}
	},
	watch: {},

	created() {

		var hrefStr = location.href.toLowerCase(); // location.href;	https://jfzsoft.com/mobile/login
		var sub = "";
		hrefStr = hrefStr.replace(/https:\/\/|http:\/\/|www./g, '');
		sub = hrefStr.substring(0, hrefStr.indexOf('.'));

		if (sub) {
			this.querySubDomainInfo(sub); //获取子域名信息
		}
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		async querySubDomainInfo(sub) { //查询子域名	
			sessionStorage.setItem('domainInfo', '');
			if (sub == 'admin5') {
				location.replace("https://dance5.top/manage/login");
				return;
			}

			if (sub && sub != 'bm') {
				const res = await handleQuerySubDomainInfo(sub);
				if (res.status == 200) {
					this.domain = res.data;
					if (this.domain && this.domain.schemeName) {
						document.title = this.domain.schemeName;
						sessionStorage.setItem('domainInfo', JSON.stringify(this.domain));
						sessionStorage.setItem('myHomePage', "/agentlogin");
						this.$router.push({
							path: '/agentlogin'
						})
						return;
					}
				}
			}
			sessionStorage.setItem('domainInfo', '');
			sessionStorage.setItem('myHomePage', "/home");
			document.title = '我是记分长';
			this.$router.push({
				path: '/home'
			})
		}
	}
}